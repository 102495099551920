import React from "react"
import { StaticQuery, graphql } from "gatsby"
import { Row, Col } from "react-bootstrap"
import Img from "gatsby-image"

const Scheda = ({ name, url }) => (
  <StaticQuery
    query={graphql`
      {
        file(relativePath: { regex: "/icons/pdf/" }) {
          childImageSharp {
            fixed(height: 60) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    `}
    render={data => (
      <div className="border shadow p-4 h-100 text-center">
        <a
          href={`${url}`}
          className="link-no-style"
          target="_blank"
          rel="noopener noreferrer"
        >
          <h6>
            <Row className="justify-content-center text-center">

              <Col xs={3}>
                <Img
                  fixed={data.file.childImageSharp.fixed}
                  className="align-self-center"
                />
              </Col>
              <Col className="text-left">
                <div className="pl-2">
                  {name[0].toUpperCase()}
                  {name.slice(1)}
                </div>
              </Col>

            </Row>

            <small></small>
          </h6>
        </a>
        <br />
      </div>
    )}
  />
)
export default Scheda
