import React from "react"
import { StaticQuery, graphql } from "gatsby"
/* import PropTypes from "prop-types" */

import { Row, Col } from "react-bootstrap"
import Scheda from "./scheda"

const ItemLink = () => (
  <StaticQuery
    query={graphql`
      {
        allFile(filter: { absolutePath: { regex: "/files/pubblicazioni/" } }) {
          edges {
            node {
              name
              publicURL
            }
          }
        }
      }
    `}
    render={data => (
      <>
        <Row className="p-5 shadow border">
          {data.allFile.edges.map(({ node }, index) => {
            return (
              <Col sm={12} md={12} lg={6} xl={3} key={index} className="p-4 ">
                <Scheda name={node.name} url={node.publicURL}></Scheda>
              </Col>
            )
          })}
        </Row>
      </>
    )}
  />
)

/* ItemLink.propTypes = {
  name: PropTypes.string,
  valore_alternativo: PropTypes.string,
}

ItemLink.defaultProps = {
  name: "",
  valore_alternativo: "",
} */

export default ItemLink
