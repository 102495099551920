import React from "react"
import { Container } from "react-bootstrap"

import ListaSchede from "./listaSchede"

const PubblicazioniPage = () => (
  <>
    <Container fluid className="my-5 p-5">
      <ListaSchede />
    </Container>
    {/* <Row></Row> */}
  </>
)

export default PubblicazioniPage
